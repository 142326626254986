import _defineProperty from"@babel/runtime/helpers/defineProperty";function ownKeys(e,r){var t=Object.keys(e);if(Object.getOwnPropertySymbols){var o=Object.getOwnPropertySymbols(e);r&&(o=o.filter(function(r){return Object.getOwnPropertyDescriptor(e,r).enumerable;})),t.push.apply(t,o);}return t;}function _objectSpread(e){for(var r=1;r<arguments.length;r++){var t=null!=arguments[r]?arguments[r]:{};r%2?ownKeys(Object(t),!0).forEach(function(r){_defineProperty(e,r,t[r]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(e,Object.getOwnPropertyDescriptors(t)):ownKeys(Object(t)).forEach(function(r){Object.defineProperty(e,r,Object.getOwnPropertyDescriptor(t,r));});}return e;}
import{getParamsFromRuntimeConfig}from'@24i/nxg-integration-backstage';
import{getDefaultBackstageClients}from'@24i/nxg-integration-backstage/src/clients/DefaultBackstageClients';
import{defaultRuntimeConfig,getDefaultSDKClients}from'@24i/nxg-sdk-smartott-defaults';
import initApp,{getMergedRuntimeConfig}from'@24i/nxg-sdk-smartott/src/Application/initApp';
import{getDefaultClientStubs}from'@24i/nxg-sdk-smartott-stubs';
import{DefaultAppStartClient}from'@24i/nxg-sdk-smartott-defaults/src/clients/AppStartClient';
import{AppVersion}from'@24i/nxg-core-utils/src/globalSingletons';
import{createFirebaseMonitoringDataClient}from"./clients/CustomFirebaseMonitoringDataClient";

import runtimeJson from"../../../platformAssets/renative.runtime.json";
import{CUSTOM_SCREEN_BUILDERS}from"./customScreens";
import{i18nOptions}from"../../locale";
import{CustomDataClient}from"../api";


import STATIC_LOGO from"../../../platformAssets/runtime/images/logo.png";

AppVersion.set(runtimeJson.appVersion+"-"+runtimeJson.timestamp);

initApp({i18nOptions:i18nOptions});

var runtimeConfig=getMergedRuntimeConfig(runtimeJson,defaultRuntimeConfig);
var staticSplashConfig={
background:null,
logo:STATIC_LOGO
};

var params=getParamsFromRuntimeConfig(runtimeConfig);

export var getClients=function getClients(){

var firebaseMonitoringDataClient=createFirebaseMonitoringDataClient(runtimeConfig);

var defaultBackstageClients=getDefaultBackstageClients(params);

defaultBackstageClients.contentDataClient=new CustomDataClient(params);


var defaultClientStubs=getDefaultClientStubs(defaultBackstageClients.contentDataClient);


var defaultSDKClients=getDefaultSDKClients(CUSTOM_SCREEN_BUILDERS,runtimeConfig);

var appStartClient=new DefaultAppStartClient(_objectSpread(_objectSpread(_objectSpread(_objectSpread({},
defaultBackstageClients),
defaultSDKClients),
defaultClientStubs),{},{
firebaseMonitoringDataClient:firebaseMonitoringDataClient})
);

return _objectSpread(_objectSpread(_objectSpread(_objectSpread({},
defaultSDKClients),
defaultClientStubs),
defaultBackstageClients),{},{
appStartClient:appStartClient,
firebaseMonitoringDataClient:firebaseMonitoringDataClient});

};

export default{
runtimeConfig:runtimeConfig,
staticSplashConfig:staticSplashConfig
};