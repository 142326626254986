import _slicedToArray from"@babel/runtime/helpers/slicedToArray";import _extends from"@babel/runtime/helpers/extends";
import React,{useEffect,useState}from'react';

import Application from'@24i/nxg-sdk-smartott/src/Application';
import{FirebaseProvider}from'@24i/nxg-sdk-smartott/src/context/Firebase';
import{ApplicationStoreProvider}from'@24i/nxg-sdk-smartott/src/context/ApplicationStore';
import Footer from'@24i/nxg-sdk-smartott/src/components/Footer';

import{DefaultDataProviders}from'@24i/nxg-sdk-smartott-shared/src/context/DefaultDataProviders';
import{DefaultSDKProviders}from'@24i/nxg-sdk-smartott/src/context/DefaultSDKProviders';
import{MonitoringDataProvider}from'@24i/nxg-sdk-smartott-shared/src/context/MonitoringData';
import{DefaultClientStubsProvider}from'@24i/nxg-sdk-smartott/src/context/DefaultClientStubsProvider';
import{ActivityIndicator}from'@24i/nxg-sdk-quarks';
import{AppStartProvider,useAppStart}from'@24i/nxg-sdk-smartott/src/context/AppStartContext';
import{QueryProvider}from'@24i/nxg-sdk-smartott-shared/src/context/Query';

import defaultTheme from"../../platformAssets/runtime/defaultTheme.json";
import config,{getClients}from"./config";

var runtimeConfig=config.runtimeConfig,staticSplashConfig=config.staticSplashConfig;

var customSequence=[
'goToLandingPage',
'goToSignInPage',
'goToHomePage'];


var AppModule=function AppModule(props){
var _useAppStart=useAppStart(),initApplication=_useAppStart.initApplication;

var _props$footerLinks=props.footerLinks,footerLinks=_props$footerLinks===void 0?[]:_props$footerLinks,_props$footerIcons=props.footerIcons,footerIcons=_props$footerIcons===void 0?[]:_props$footerIcons;

useEffect(function(){
initApplication();
},[]);

return(
React.createElement(Application.Main,_extends({},
props,{
staticSplashConfig:staticSplashConfig,
renderFooter:function renderFooter(){return React.createElement(Footer,{links:footerLinks,icons:footerIcons});}})
));

};

var App=function App(props){
var _useState=useState(),_useState2=_slicedToArray(_useState,2),clients=_useState2[0],setClients=_useState2[1];
useEffect(function(){
setClients(getClients());
},[]);

if(clients){
return(
React.createElement(QueryProvider,null,
React.createElement(ApplicationStoreProvider,{runtimeConfig:runtimeConfig},
React.createElement(DefaultDataProviders,{clients:clients,options:{defaultTheme:defaultTheme}},
React.createElement(FirebaseProvider,null,
React.createElement(DefaultSDKProviders,{clients:clients},
React.createElement(DefaultClientStubsProvider,{clients:clients},
React.createElement(AppStartProvider,{
client:clients.appStartClient,
sequence:customSequence,
queryClient:clients.queryClient},

React.createElement(MonitoringDataProvider,{
clients:[
clients.sentryMonitoringDataClient,
clients.firebaseMonitoringDataClient]},


React.createElement(AppModule,_extends({},props,{clients:clients}))
)
)
)
)
)
)
)
));

}

return React.createElement(ActivityIndicator,null);
};

export default App;