import _defineProperty from"@babel/runtime/helpers/defineProperty";import _toConsumableArray from"@babel/runtime/helpers/toConsumableArray";import _slicedToArray from"@babel/runtime/helpers/slicedToArray";function ownKeys(e,r){var t=Object.keys(e);if(Object.getOwnPropertySymbols){var o=Object.getOwnPropertySymbols(e);r&&(o=o.filter(function(r){return Object.getOwnPropertyDescriptor(e,r).enumerable;})),t.push.apply(t,o);}return t;}function _objectSpread(e){for(var r=1;r<arguments.length;r++){var t=null!=arguments[r]?arguments[r]:{};r%2?ownKeys(Object(t),!0).forEach(function(r){_defineProperty(e,r,t[r]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(e,Object.getOwnPropertyDescriptors(t)):ownKeys(Object(t)).forEach(function(r){Object.defineProperty(e,r,Object.getOwnPropertyDescriptor(t,r));});}return e;}import{isPlatformWeb}from'@24i/nxg-core-utils';




import{WEB_SCREEN_MAX_WIDTH}from'@24i/nxg-sdk-gluons/src/utils/constants';
import{useDimensions}from'@24i/nxg-sdk-quantum';
import{useStore}from'@24i/nxg-sdk-smartott/src/context/ApplicationStore';
import{useNavigationConfigQuery}from'@24i/nxg-sdk-smartott/src/hooks/query/useNavigationConfigQuery';
import{useRegister}from'@24i/nxg-sdk-smartott/src/hooks/useRegister';




import{useEffect,useState}from'react';
import useShared from"./useShared";
import{redirectToAuthentication}from"../../SigninScreen/redirectToAuthentication";
import{redirectToSignup}from"../../SignupScreen/redirectToSignup";

var useViewModel=function useViewModel(props){
var shared=useShared(props);
var getUserProfiles=shared.getUserProfiles,isProfileLoading=shared.isProfileLoading;
var _useStore=useStore(),userData=_useStore.userData;
var _useNavigationConfigQ=useNavigationConfigQuery(),navigationConfig=_useNavigationConfigQ.navigationConfig;
var _useRegister=useRegister(),accountRegistration=_useRegister.accountRegistration;
var _useDimensions=useDimensions(),width=_useDimensions.width;
var _useState=useState([]),_useState2=_slicedToArray(_useState,2),menuNavItems=_useState2[0],setMenuNavItems=_useState2[1];

useEffect(function(){
getUserProfiles();
},[userData]);

useEffect(function(){

var navigationConfigWeb=navigationConfig;

if(!navigationConfigWeb)return;

var menuItems=_toConsumableArray(navigationConfigWeb.XS.moreScreen);

if(isPlatformWeb&&width<WEB_SCREEN_MAX_WIDTH.XS){




setMenuNavItems(
menuItems.filter(function(item){return!item.name.includes('sign-up');})
);
}else{
setMenuNavItems(menuItems);
}
},[width]);

return _objectSpread(_objectSpread({},
shared),{},{
isLoading:isProfileLoading,
showBottomAuthSection:!userData,
showProfilePicker:false,
onSignInPress:redirectToAuthentication,
onRegisterPress:accountRegistration?redirectToSignup:undefined,
menuItems:menuNavItems},
props);

};

export{useViewModel};