import _extends from"@babel/runtime/helpers/extends";import React from'react';
import*as Sentry from"@sentry/react";
import{useTranslation}from'react-i18next';
import runtimeJson from"../../../platformAssets/renative.runtime.json";
import"../styles/global.css";

import App from"../app";


try{
var monitoringEnvironment=runtimeJson.monitoringEnvironment;
Sentry.init({
enableNative:false,
dsn:"https://387cda5e94534eedaf8b705c93f63b02@sentry.24i.com/275",
environment:monitoringEnvironment
});
}catch(err){

console.log('Error while initializing Sentry for web');
}

var footerIcons=[
{
font:'fontAwesome',
name:'instagram',
href:'https://www.instagram.com/tastemade/'
},
{
font:'fontAwesome',
name:'facebook',
href:'https://www.facebook.com/tastemade'
},
{
font:'fontAwesome',
name:'snapchat',
href:'https://www.snapchat.com/discover/Tastemade/7351865381'
},
{
font:'fontAwesome',
name:'youtube',
href:'https://www.youtube.com/user/tastemade'
},
{
font:'fontAwesome',
name:'twitter',
href:'https://twitter.com/tastemade'
},
{
font:'fontAwesome',
name:'tiktok',
href:'https://www.tiktok.com/@tastemade?lang=en'
},
{
font:'fontAwesome',
name:'pinterest',
href:'https://www.pinterest.com/tastemade/'
},
{
font:'fontAwesome',
name:'home',
href:'https://tastemade.com'
}];


var WebApp=function WebApp(props){
var _useTranslation=useTranslation(['tas']),t=_useTranslation.t;

var footerLinks=[
{title:t('footer.contactUs'),href:'https://www.24i.com/contact/'},
{title:t('footer.news'),href:'https://www.24i.com/articles/'}];


return React.createElement(App,_extends({footerLinks:footerLinks,footerIcons:footerIcons},props));
};

export default WebApp;