import _regeneratorRuntime from"@babel/runtime/regenerator";


import React,{useEffect,useRef}from'react';
import{View}from'@24i/nxg-sdk-quarks';
import{useRouter}from'next/router';
import Loader from'@24i/nxg-sdk-gluons/src/components/ui/Loader';
import{useUserData}from'@24i/nxg-sdk-smartott-shared/src/context/UserData';
import{logError}from'@24i/nxg-core-utils/src/logger';
import{useStore}from'@24i/nxg-sdk-smartott/src/context/ApplicationStore';
import{useStartPageNavigation}from'@24i/nxg-sdk-smartott/src/context/AppStartContext/hooks/index.web';
import{ApplicationStates,useAppStart}from'@24i/nxg-sdk-smartott/src/context/AppStartContext';
import{useQueryClient}from'react-query';

import{loaderStyle,webViewLoaderStyle}from"./utils";

import{
parseURL,
getUserCode,
getUserTokenBasedOnCode,
setUserToken}from"../../../shared/utils";


var CustomOverridenSignupScreen=function CustomOverridenSignupScreen(){var _router$query3;
var userDataClient=useUserData();
var _useAppStart=useAppStart(),appState=_useAppStart.appState,runStartSequence=_useAppStart.runStartSequence;
var codeFromURL=useRef('');
var _useStartPageNavigati=useStartPageNavigation(),login=_useStartPageNavigati.login;
var _useStore=useStore(),setUserData=_useStore.setUserData,userData=_useStore.userData;
var queryClient=useQueryClient();
var router=useRouter();

useEffect(function(){var _router$query;
if((_router$query=router.query)!=null&&_router$query.code){var _router$query2;
codeFromURL.current=(_router$query2=router.query)==null?void 0:_router$query2.code;
}
},[(_router$query3=router.query)==null?void 0:_router$query3.code]);

var continueStartSequence=function continueStartSequence(){return _regeneratorRuntime.async(function continueStartSequence$(_context){while(1)switch(_context.prev=_context.next){case 0:_context.prev=0;_context.next=3;return _regeneratorRuntime.awrap(



queryClient.invalidateQueries());case 3:
runStartSequence(undefined,router);_context.next=9;break;case 6:_context.prev=6;_context.t0=_context["catch"](0);

logError(_context.t0);case 9:case"end":return _context.stop();}},null,null,[[0,6]],Promise);};



useEffect(function(){
if(userData&&appState===ApplicationStates.initialNavigationComplete){
continueStartSequence();
}
},[appState,userData]);

useEffect(function(){
var getUserData=function getUserData(){var _await$getUserTokenBa,userToken,user;return _regeneratorRuntime.async(function getUserData$(_context2){while(1)switch(_context2.prev=_context2.next){case 0:_context2.prev=0;_context2.next=3;return _regeneratorRuntime.awrap(

getUserTokenBasedOnCode(codeFromURL.current));case 3:_await$getUserTokenBa=_context2.sent;userToken=_await$getUserTokenBa.token;_context2.next=7;return _regeneratorRuntime.awrap(
setUserToken(userToken));case 7:_context2.next=9;return _regeneratorRuntime.awrap(
userDataClient.fetchUser());case 9:user=_context2.sent;
setUserData(user);
continueStartSequence();
login();_context2.next=18;break;case 15:_context2.prev=15;_context2.t0=_context2["catch"](0);

logError(_context2.t0);case 18:case"end":return _context2.stop();}},null,null,[[0,15]],Promise);};


if(codeFromURL.current&&!userData)getUserData();
},[codeFromURL.current,appState]);

useEffect(function(){
var fetchServiceURL=function fetchServiceURL(){var userCodeResponse,hasTag,hostUrl,authURLLocal,parsedLocalURL,redirectUrl,authURL;return _regeneratorRuntime.async(function fetchServiceURL$(_context3){while(1)switch(_context3.prev=_context3.next){case 0:_context3.next=2;return _regeneratorRuntime.awrap(
getUserCode());case 2:userCodeResponse=_context3.sent;
hasTag=userCodeResponse.url.includes('#login');
hostUrl=hasTag?
userCodeResponse.url.slice(0,userCodeResponse.url.length-6):
userCodeResponse.url;
authURLLocal=hostUrl+"?code="+userCodeResponse.code;
if(typeof window!=='undefined'){
parsedLocalURL=parseURL(window.location.href.toString());
redirectUrl=parsedLocalURL.protocol+"//"+parsedLocalURL.host+"/register?code="+userCodeResponse.code;
authURL=authURLLocal+"&redirectUrl="+redirectUrl+"#register";
window.location.href=authURL;
}case 7:case"end":return _context3.stop();}},null,null,null,Promise);};

if(!codeFromURL.current&&!userData)fetchServiceURL();
},[]);

return(
React.createElement(View,{style:webViewLoaderStyle},
React.createElement(Loader,{additionalStyles:loaderStyle,color:"white",size:"large"})
));

};

export default CustomOverridenSignupScreen;