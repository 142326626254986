import _defineProperty from"@babel/runtime/helpers/defineProperty";import _slicedToArray from"@babel/runtime/helpers/slicedToArray";function ownKeys(e,r){var t=Object.keys(e);if(Object.getOwnPropertySymbols){var o=Object.getOwnPropertySymbols(e);r&&(o=o.filter(function(r){return Object.getOwnPropertyDescriptor(e,r).enumerable;})),t.push.apply(t,o);}return t;}function _objectSpread(e){for(var r=1;r<arguments.length;r++){var t=null!=arguments[r]?arguments[r]:{};r%2?ownKeys(Object(t),!0).forEach(function(r){_defineProperty(e,r,t[r]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(e,Object.getOwnPropertyDescriptors(t)):ownKeys(Object(t)).forEach(function(r){Object.defineProperty(e,r,Object.getOwnPropertyDescriptor(t,r));});}return e;}import{useState}from'react';

import{log}from'@24i/nxg-core-utils/src/logger';

import{useSwitchProfile}from'@24i/nxg-sdk-smartott/src/hooks/useSwitchProfile';
import{useUserData}from'@24i/nxg-sdk-smartott-shared/src/context/UserData';
import{useFeature}from'@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import{useStore}from'@24i/nxg-sdk-smartott/src/context/ApplicationStore';


var useShared=function useShared(props){var _useFeature;
var _useState=useState([]),_useState2=_slicedToArray(_useState,2),profiles=_useState2[0],setProfiles=_useState2[1];
var _useState3=useState(false),_useState4=_slicedToArray(_useState3,2),isProfileLoading=_useState4[0],setIsProfileLoading=_useState4[1];
var userDataClient=useUserData();

var _useStore=useStore(),userData=_useStore.userData;
var _useSwitchProfile=useSwitchProfile(),switchProfile=_useSwitchProfile.switchProfile;

var adobePrimetimeEnabled=(_useFeature=useFeature('adobePrimetime'))==null?void 0:_useFeature.enabled;



var getUserProfiles=function getUserProfiles(){
setIsProfileLoading(true);
if(userData)
userDataClient.
fetchUserProfiles().
then(function(usersProfiles){
setProfiles(usersProfiles);
setIsProfileLoading(false);
}).
catch(function(err){
log(err);
setIsProfileLoading(false);
});else
{
setProfiles([]);
setIsProfileLoading(false);
}
};


var selectProfile=function selectProfile(newSelectedProfile){
switchProfile(newSelectedProfile);
};

var showBottomAuthSection=adobePrimetimeEnabled||!userData;

return _objectSpread({

profiles:profiles,
selectProfile:selectProfile,
isLoading:isProfileLoading,
showBottomAuthSection:showBottomAuthSection,
showProfilePicker:false,

getUserProfiles:getUserProfiles,
isProfileLoading:isProfileLoading},
props);

};

export default useShared;