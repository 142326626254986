import _defineProperty from"@babel/runtime/helpers/defineProperty";function ownKeys(e,r){var t=Object.keys(e);if(Object.getOwnPropertySymbols){var o=Object.getOwnPropertySymbols(e);r&&(o=o.filter(function(r){return Object.getOwnPropertyDescriptor(e,r).enumerable;})),t.push.apply(t,o);}return t;}function _objectSpread(e){for(var r=1;r<arguments.length;r++){var t=null!=arguments[r]?arguments[r]:{};r%2?ownKeys(Object(t),!0).forEach(function(r){_defineProperty(e,r,t[r]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(e,Object.getOwnPropertyDescriptors(t)):ownKeys(Object(t)).forEach(function(r){Object.defineProperty(e,r,Object.getOwnPropertyDescriptor(t,r));});}return e;}



import React from'react';
import i18next from'i18next';

import{SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS}from'@24i/nxg-sdk-smartott/src/navigation/navigationConfig/screenConfigBuilders';




import SettingsScreen from'@24i/nxg-sdk-smartott/src/screens/SettingsScreen';
import{useTheme}from'@24i/nxg-sdk-higgs';
import ActionButton from'@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import getOpenModalLinkStyles from'@24i/nxg-sdk-smartott/src/navigation/components/TopBarMenu/components/OpenModalLink/styles';
import SigninScreen from"../screens/SigninScreen";
import SignupScreen from"../screens/SignupScreen";
import MoreScreen from"../screens/MoreScreen";
import LanguageScreen from"../screens/LanguageScreen";
import{redirectToAuthentication}from"../screens/SigninScreen/redirectToAuthentication";

function CustomSignInScreenComponent(_ref){var menuItem=_ref.menuItem;
var _useTheme=useTheme(),theme=_useTheme.theme;
var _styles=getOpenModalLinkStyles(theme);
var menuTitle=menuItem.menuTitle;

return React.createElement(ActionButton,{onPress:redirectToAuthentication,title:menuTitle,styles:function styles(){return _styles;}});
}

export var CUSTOM_SCREEN_BUILDERS=_objectSpread(_objectSpread({},
SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS),{},{
buildSignInScreen:function buildSignInScreen(menuItem,context){
var originalBuild=SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS.buildSignInScreen(
menuItem,
context
);

return _objectSpread(_objectSpread({},
originalBuild),{},{
nativeRenderOptions:{
Component:SigninScreen
},
renderWebLink:function renderWebLink(_ref2){var linkMenuItem=_ref2.menuItem;return(
React.createElement(CustomSignInScreenComponent,{menuItem:linkMenuItem}));},

skipStartSequence:true});

},

buildMoreScreen:function buildMoreScreen(menuItem,context){
var originalBuild=SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS.buildMoreScreen(
menuItem,
context
);
var t=i18next.getFixedT(i18next.language,'tas');
var menuTitle=t('common.more');
return _objectSpread(_objectSpread({},
originalBuild),{},{
menuTitle:menuTitle,
name:'appstage-screen-more',
correspondingProductScreen:'More',
icon:'bars',
nativeRenderOptions:{
Component:MoreScreen.Main
},
options:{
headerShown:false
},
placementNative:{},
placementWeb:{},
accessMode:10});

},

buildLanguageScreen:function buildLanguageScreen(menuItem,context){
var originalBuild=SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS.buildLanguageScreen(
menuItem,
context
);

return _objectSpread(_objectSpread({},
originalBuild),{},{
name:'appstage-screen-language',
nativeRenderOptions:{
Component:LanguageScreen
},
placementWeb:{}});

},

buildSettingsScreen:function buildSettingsScreen(menuItem,context){
var originalBuild=SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS.buildSettingsScreen(
menuItem,
context
);

return _objectSpread(_objectSpread({},
originalBuild),{},{
nativeRenderOptions:{
Component:SettingsScreen.Main
}});

},

buildSignUpScreen:function buildSignUpScreen(menuItem,context){
var originalBuild=SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS.buildSignUpScreen(
menuItem,
context
);

return _objectSpread(_objectSpread({},
originalBuild),{},{
nativeRenderOptions:{
Component:SignupScreen
}});

}});