import _defineProperty from"@babel/runtime/helpers/defineProperty";import _classCallCheck from"@babel/runtime/helpers/classCallCheck";import _createClass from"@babel/runtime/helpers/createClass";import _possibleConstructorReturn from"@babel/runtime/helpers/possibleConstructorReturn";import _get from"@babel/runtime/helpers/get";import _getPrototypeOf from"@babel/runtime/helpers/getPrototypeOf";import _inherits from"@babel/runtime/helpers/inherits";import _regeneratorRuntime from"@babel/runtime/regenerator";function ownKeys(e,r){var t=Object.keys(e);if(Object.getOwnPropertySymbols){var o=Object.getOwnPropertySymbols(e);r&&(o=o.filter(function(r){return Object.getOwnPropertyDescriptor(e,r).enumerable;})),t.push.apply(t,o);}return t;}function _objectSpread(e){for(var r=1;r<arguments.length;r++){var t=null!=arguments[r]?arguments[r]:{};r%2?ownKeys(Object(t),!0).forEach(function(r){_defineProperty(e,r,t[r]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(e,Object.getOwnPropertyDescriptors(t)):ownKeys(Object(t)).forEach(function(r){Object.defineProperty(e,r,Object.getOwnPropertyDescriptor(t,r));});}return e;}function _callSuper(t,o,e){return o=_getPrototypeOf(o),_possibleConstructorReturn(t,_isNativeReflectConstruct()?Reflect.construct(o,e||[],_getPrototypeOf(t).constructor):o.apply(t,e));}function _isNativeReflectConstruct(){try{var t=!Boolean.prototype.valueOf.call(Reflect.construct(Boolean,[],function(){}));}catch(t){}return(_isNativeReflectConstruct=function _isNativeReflectConstruct(){return!!t;})();}import{encode}from'base-64';
import{ContentDataClient}from'@24i/nxg-integration-backstage';

import{MIME_TYPE}from'@24i/player-base';

export var CustomDataClient=function(_ContentDataClient){_inherits(CustomDataClient,_ContentDataClient);function CustomDataClient(){_classCallCheck(this,CustomDataClient);return _callSuper(this,CustomDataClient,arguments);}_createClass(CustomDataClient,[{key:"prepareStream",value:

function prepareStream(asset,assetId){var isCast,promise,_args=arguments;return _regeneratorRuntime.async(function prepareStream$(_context){while(1)switch(_context.prev=_context.next){case 0:isCast=_args.length>2&&_args[2]!==undefined?_args[2]:false;
promise=_get(_getPrototypeOf(CustomDataClient.prototype),"prepareStream",this).call(this,asset,assetId,isCast);return _context.abrupt("return",

promise.then(function(result){
if(result){
var newResult=_objectSpread({},result);
var textTracks=result.textTracks;
var isMediatailor=
result.ssai&&result.ssai.type==='awsmediatailor'&&result.ssai.sessionUrl;
var validTextTracks=
textTracks&&textTracks.length&&textTracks.filter(function(tt){return tt.url&&tt.mimeType===MIME_TYPE.WEBVTT;});


newResult.textTracks=validTextTracks;

if(isMediatailor&&validTextTracks&&validTextTracks.length){
var mediaTailorSsai=result.ssai;

newResult.textTracks=[];
var newUrl=new URL(mediaTailorSsai.sessionUrl);
newUrl.searchParams.append(
'subtitles',
encode(
JSON.stringify(
validTextTracks.
map(function(tt){return{
uri:tt.url,
language:tt.language,
name:tt.label
};})
)
)
);
mediaTailorSsai.sessionUrl=newUrl.toString();
}
return newResult;
}
return result;
}));case 3:case"end":return _context.stop();}},null,this,null,Promise);}}]);return CustomDataClient;}(ContentDataClient);