

import en from'@24i/prd-localization-files-prj1770tas/dist/en.json';
import es from'@24i/prd-localization-files-prj1770tas/dist/es.json';
import pt from'@24i/prd-localization-files-prj1770tas/dist/pt.json';

export var i18nOptions={
resources:{
en:{tas:en,sott:en},
es:{tas:es,sott:es},
pt:{tas:pt,sott:pt}
},
defaultNS:'tas',
ns:['tas'],
react:{
wait:true,
useSuspense:false
},
fallbackLng:'en'
};