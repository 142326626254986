import _regeneratorRuntime from"@babel/runtime/regenerator";import{getUserCode,parseURL}from"../../../shared/utils";

export var redirectToSignup=function redirectToSignup(){var userCodeResponse,hasTag,hostUrl,authURLLocal,parsedLocalURL,redirectUrl,authURL;return _regeneratorRuntime.async(function redirectToSignup$(_context){while(1)switch(_context.prev=_context.next){case 0:_context.next=2;return _regeneratorRuntime.awrap(
getUserCode());case 2:userCodeResponse=_context.sent;
hasTag=userCodeResponse.url.includes('#login');
hostUrl=hasTag?userCodeResponse.url.slice(0,userCodeResponse.url.length-6):userCodeResponse.url;
authURLLocal=hostUrl+"?code="+userCodeResponse.code;
if(typeof window!=='undefined'){
parsedLocalURL=parseURL(window.location.href.toString());
redirectUrl=parsedLocalURL.protocol+"//"+parsedLocalURL.host+"/register?code="+userCodeResponse.code;
authURL=authURLLocal+"&redirectUrl="+redirectUrl+"#register";
window.location.href=authURL;
}case 7:case"end":return _context.stop();}},null,null,null,Promise);};