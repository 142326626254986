import _classCallCheck from"@babel/runtime/helpers/classCallCheck";import _createClass from"@babel/runtime/helpers/createClass";import _regeneratorRuntime from"@babel/runtime/regenerator";

export var FirebaseMonitoringDataClient=function(){
function FirebaseMonitoringDataClient(props){_classCallCheck(this,FirebaseMonitoringDataClient);this.




name='FirebaseAnalytics';this.

env='Development';this.env=props!=null&&props.monitoringEnvironment?props==null?void 0:props.monitoringEnvironment:'Development';this.props=props;}_createClass(FirebaseMonitoringDataClient,[{key:"init",value:




function init(){return _regeneratorRuntime.async(function init$(_context){while(1)switch(_context.prev=_context.next){case 0:case"end":return _context.stop();}},null,null,null,Promise);}},{key:"logEvent",value:


function logEvent(){return _regeneratorRuntime.async(function logEvent$(_context2){while(1)switch(_context2.prev=_context2.next){case 0:case"end":return _context2.stop();}},null,null,null,Promise);}},{key:"logException",value:


function logException(){return _regeneratorRuntime.async(function logException$(_context3){while(1)switch(_context3.prev=_context3.next){case 0:case"end":return _context3.stop();}},null,null,null,Promise);}}]);return FirebaseMonitoringDataClient;}();


export var createFirebaseMonitoringDataClient=function createFirebaseMonitoringDataClient(runtimeConfig){return(
new FirebaseMonitoringDataClient(runtimeConfig));};