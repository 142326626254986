
import React from'react';
import{
defaultRenderMoreScreenAuthSection,
defaultRenderMoreScreenMenu,
defaultRenderProfilePicker,
getMoreScreenStyles}from
'@24i/nxg-sdk-smartott/src/screens/MoreScreen/View';
import*as prerenderFunctions from'@24i/nxg-sdk-smartott/src/pages/more';
import MoreScreenAuthSection from'@24i/nxg-sdk-smartott/src/screens/MoreScreen/components/MoreScreenAuthSection';
import MoreScreenMenu from'@24i/nxg-sdk-smartott/src/screens/MoreScreen/components/MoreScreenMenu';
import ProfilePicker from'@24i/nxg-sdk-smartott/src/screens/MoreScreen/components/ProfilePicker';

import{useViewModel}from"./viewModel";
import View from"./View";

export default{
Main:function Main(props){
var viewModelProps=useViewModel(props);
return React.createElement(View,viewModelProps);
},
View:View,
useViewModel:useViewModel,
page:{
more:prerenderFunctions
},

MoreScreenAuthSection:MoreScreenAuthSection,
MoreScreenMenu:MoreScreenMenu,
ProfilePicker:ProfilePicker,

defaultRenderMoreScreenAuthSection:defaultRenderMoreScreenAuthSection,
defaultRenderMoreScreenMenu:defaultRenderMoreScreenMenu,
defaultRenderProfilePicker:defaultRenderProfilePicker,

getDefaultStyles:getMoreScreenStyles
};