import _regeneratorRuntime from"@babel/runtime/regenerator";import{getUserCode,parseURL}from"../../../shared/utils";

export var redirectToAuthentication=function redirectToAuthentication(){var LOGIN_TAG,userCodeResponse,hasTag,hostUrl,authURLLocal,tag,parsedLocalURL,redirectUrl,authURL;return _regeneratorRuntime.async(function redirectToAuthentication$(_context){while(1)switch(_context.prev=_context.next){case 0:
LOGIN_TAG='#login';_context.next=3;return _regeneratorRuntime.awrap(
getUserCode());case 3:userCodeResponse=_context.sent;
hasTag=userCodeResponse.url.includes(LOGIN_TAG);
hostUrl=hasTag?
userCodeResponse.url.slice(0,userCodeResponse.url.length-6):
userCodeResponse.url;
authURLLocal=hostUrl+"?code="+userCodeResponse.code;
tag=hasTag&&LOGIN_TAG;
if(typeof window!=='undefined'){
parsedLocalURL=parseURL(window.location.href.toString());
redirectUrl=parsedLocalURL.protocol+"//"+parsedLocalURL.host+"/signin?code="+userCodeResponse.code;
authURL=authURLLocal+"&redirectUrl="+redirectUrl+(tag||'');
if(!hasTag){
authURL+='#login';
}
window.location.href=authURL;
}case 9:case"end":return _context.stop();}},null,null,null,Promise);};