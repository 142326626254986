import React from'react';
import{useTranslation}from'react-i18next';
import{withWebHeader}from'@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader';
import{firstLetterUppercase}from'@24i/nxg-core-utils';
import SettingsDropdownPicker from'@24i/nxg-sdk-smartott/src/components/SettingsDropdownPicker';

var CustomOverridenLanguageScreen=function CustomOverridenLanguageScreen(){
var _useTranslation=useTranslation(['tas']),t=_useTranslation.t,i18n=_useTranslation.i18n;

var langArray=Object.keys(
Object.fromEntries(
Object.entries(i18n.options.resources||{}).filter(
function(item){return item[1][i18n.options.defaultNS];}
)
)
);

var getTestID=function getTestID(index){return t("common."+langArray[index]).toLowerCase()+"_button";};
return(
React.createElement(SettingsDropdownPicker,{
options:langArray.map(function(lang){return firstLetterUppercase(t("settings.language."+lang));}),
selectedItem:firstLetterUppercase(t("settings.language."+i18n.language)),
onSelectionPress:function onSelectionPress(index){return i18n.changeLanguage(langArray[index]);},
title:t('settings.language.description'),
buttonTestID:"app_language_picker",
getItemsTestID:getTestID}
));

};

export default withWebHeader(CustomOverridenLanguageScreen);