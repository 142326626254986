import _defineProperty from"@babel/runtime/helpers/defineProperty";function ownKeys(e,r){var t=Object.keys(e);if(Object.getOwnPropertySymbols){var o=Object.getOwnPropertySymbols(e);r&&(o=o.filter(function(r){return Object.getOwnPropertyDescriptor(e,r).enumerable;})),t.push.apply(t,o);}return t;}function _objectSpread(e){for(var r=1;r<arguments.length;r++){var t=null!=arguments[r]?arguments[r]:{};r%2?ownKeys(Object(t),!0).forEach(function(r){_defineProperty(e,r,t[r]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(e,Object.getOwnPropertyDescriptors(t)):ownKeys(Object(t)).forEach(function(r){Object.defineProperty(e,r,Object.getOwnPropertyDescriptor(t,r));});}return e;}import _regeneratorRuntime from"@babel/runtime/regenerator";import{DeviceInfo,Storage}from'@24i/nxg-sdk-quantum';import Platform from"react-native-web/dist/exports/Platform";

import{getDeviceId}from'@24i/nxg-core-utils';
import{getRuntimeConfig}from'@24i/nxg-sdk-smartott/src/Application/initApp';
import i18n from'i18next';
import{ASYNC_STORAGE_KEY_USER_TOKEN}from'@24i/nxg-core-utils/src/constants';

export var setUserToken=function setUserToken(token){return _regeneratorRuntime.async(function setUserToken$(_context){while(1)switch(_context.prev=_context.next){case 0:_context.prev=0;

Storage.setItem(ASYNC_STORAGE_KEY_USER_TOKEN,token);return _context.abrupt("return",
token);case 5:_context.prev=5;_context.t0=_context["catch"](0);

console.error('Error in setUserToken:',_context.t0);return _context.abrupt("return",_context.t0);case 9:case"end":return _context.stop();}},null,null,[[0,5]],Promise);};




export var getDeviceType=function getDeviceType(){
var isWeb=DeviceInfo.getDeviceType().toString()==='Web';
var os=Platform.OS.toLowerCase();
var isTv=Platform.isTV;
if(isWeb)return'web';
if(isTv)return'smarttv';
return os;
};

















export function parseURL(url){
var urlObj=new URL(url);

return{
protocol:urlObj.protocol,
host:urlObj.host,
hostname:urlObj.hostname,
port:urlObj.port,
pathname:urlObj.pathname,
search:urlObj.search,
hash:urlObj.hash
};
}

export var getUserCode=function getUserCode(){var DeviceType,customService,serviceId,appIdArray,applicationId,getLanguage,headersLocal,res,data;return _regeneratorRuntime.async(function getUserCode$(_context2){while(1)switch(_context2.prev=_context2.next){case 0:_context2.prev=0;

DeviceType=getDeviceType();
customService=getRuntimeConfig('apiService');
serviceId=customService.id;
appIdArray=customService.appId;
applicationId=appIdArray[DeviceType==='smarttv'?'tvos':DeviceType];
getLanguage=function getLanguage(){return i18n.language;};_context2.t0=_objectSpread;_context2.t1=_objectSpread;_context2.t2=


getLanguage();_context2.next=12;return _regeneratorRuntime.awrap(


getDeviceId());case 12:_context2.t3=_context2.sent;_context2.t4=
getDeviceType();_context2.t5={'Accept-Language':_context2.t2,Accept:'application/json','Content-Type':'application/json','X-Device-ID':_context2.t3,'X-Device-Type':_context2.t4};_context2.t6=
serviceId&&{'X-Service-ID':serviceId};_context2.t7=(0,_context2.t1)(_context2.t5,_context2.t6);_context2.t8=
applicationId&&{'X-Application-ID':applicationId};headersLocal=(0,_context2.t0)(_context2.t7,_context2.t8);_context2.next=21;return _regeneratorRuntime.awrap(


fetch("https://backstage-api.com/user/code",{
method:'GET',
headers:headersLocal
}));case 21:res=_context2.sent;_context2.next=24;return _regeneratorRuntime.awrap(

res.json());case 24:data=_context2.sent;return _context2.abrupt("return",

data);case 28:_context2.prev=28;_context2.t9=_context2["catch"](0);

console.error('Error in getUserCode:',_context2.t9);return _context2.abrupt("return",
null);case 32:case"end":return _context2.stop();}},null,null,[[0,28]],Promise);};







export var getUserTokenBasedOnCode=function getUserTokenBasedOnCode(
code){var DeviceType,customService,serviceId,appIdArray,applicationId,getLanguage,headersLocal,bodyLocal,res,raw;return _regeneratorRuntime.async(function getUserTokenBasedOnCode$(_context3){while(1)switch(_context3.prev=_context3.next){case 0:

DeviceType=getDeviceType();
customService=getRuntimeConfig('apiService');
serviceId=customService.id;
appIdArray=customService.appId;
applicationId=appIdArray[DeviceType==='smarttv'?'tvos':DeviceType];
getLanguage=function getLanguage(){return i18n.language;};_context3.t0=_objectSpread;_context3.t1=_objectSpread;_context3.t2=


getLanguage();_context3.next=11;return _regeneratorRuntime.awrap(


getDeviceId());case 11:_context3.t3=_context3.sent;_context3.t4=
getDeviceType();_context3.t5={'Accept-Language':_context3.t2,Accept:'application/json','Content-Type':'application/json','X-Device-ID':_context3.t3,'X-Device-Type':_context3.t4};_context3.t6=
serviceId&&{'X-Service-ID':serviceId};_context3.t7=(0,_context3.t1)(_context3.t5,_context3.t6);_context3.t8=
applicationId&&{'X-Application-ID':applicationId};headersLocal=(0,_context3.t0)(_context3.t7,_context3.t8);

bodyLocal={
code:""+code
};_context3.prev=19;_context3.next=22;return _regeneratorRuntime.awrap(


fetch("https://backstage-api.com/user/code/verify",{
method:'POST',
headers:headersLocal,
body:JSON.stringify(bodyLocal)
}));case 22:res=_context3.sent;if(!

res.ok){_context3.next=28;break;}_context3.next=26;return _regeneratorRuntime.awrap(
res.json());case 26:raw=_context3.sent;return _context3.abrupt("return",
raw);case 28:return _context3.abrupt("return",

null);case 31:_context3.prev=31;_context3.t9=_context3["catch"](19);

console.error(_context3.t9);return _context3.abrupt("return",
null);case 35:case"end":return _context3.stop();}},null,null,[[19,31]],Promise);};