import React,{useEffect}from'react';import QuarksScrollView from"react-native-web/dist/exports/ScrollView";import View from"react-native-web/dist/exports/View";

import{useTheme}from'@24i/nxg-sdk-higgs';
import{isPlatformWeb}from'renative';

import{overridable}from'@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import useSafeAreaInsets from'@24i/nxg-sdk-gluons/src/hooks/useSafeAreaInsets';

import LoadingWrapper from'@24i/nxg-sdk-smartott/src/components/LoadingWrapper';
import{WEB_SCREEN_MAX_WIDTH}from'@24i/nxg-sdk-gluons/src/utils/constants';
import{useDimensions}from'@24i/nxg-sdk-quantum';
import{useRouter}from'@24i/nxg-core-router/src/NextRouter';
import MoreScreenAuthSection from'@24i/nxg-sdk-smartott/src/screens/MoreScreen/components/MoreScreenAuthSection';
import MoreScreenMenu from'@24i/nxg-sdk-smartott/src/screens/MoreScreen/components/MoreScreenMenu';
import ProfilePicker from'@24i/nxg-sdk-smartott/src/screens/MoreScreen/components/ProfilePicker';
import{getBottomBarHeight}from'@24i/nxg-sdk-smartott/src/utils';
import getMoreScreenStyles from'@24i/nxg-sdk-smartott/src/screens/MoreScreen/styles';




import{SOTT_DEFAULT_WEB_SCREENS}from'@24i/nxg-sdk-smartott/src/navigation/constants';


export var defaultRenderProfilePicker=function defaultRenderProfilePicker(props){return(
React.createElement(ProfilePicker,props));};

export var defaultRenderMoreScreenMenu=function defaultRenderMoreScreenMenu(props){return(
React.createElement(MoreScreenMenu,props));};

export var defaultRenderMoreScreenAuthSection=function defaultRenderMoreScreenAuthSection(
props){return(
React.createElement(MoreScreenAuthSection,props));};

var ScrollView=isPlatformWeb?View:QuarksScrollView;

var MoreScreen=function MoreScreen(props){
var _props$styles=













props.styles,getStyles=_props$styles===void 0?getMoreScreenStyles:_props$styles,_props$profiles=props.profiles,profiles=_props$profiles===void 0?[]:_props$profiles,_props$selectProfile=props.selectProfile,selectProfile=_props$selectProfile===void 0?function(){return null;}:_props$selectProfile,_props$isLoading=props.isLoading,isLoading=_props$isLoading===void 0?true:_props$isLoading,_props$showProfilePic=props.showProfilePicker,showProfilePicker=_props$showProfilePic===void 0?false:_props$showProfilePic,menuItems=props.menuItems,onSignInPress=props.onSignInPress,onRegisterPress=props.onRegisterPress,_props$linearGradient=props.linearGradientProps,linearGradientProps=_props$linearGradient===void 0?{}:_props$linearGradient,_props$showBottomAuth=props.showBottomAuthSection,showBottomAuthSection=_props$showBottomAuth===void 0?true:_props$showBottomAuth,_props$renderProfileP=props.renderProfilePicker,renderProfilePicker=_props$renderProfileP===void 0?defaultRenderProfilePicker:_props$renderProfileP,_props$renderMoreScre=props.renderMoreScreenMenu,renderMoreScreenMenu=_props$renderMoreScre===void 0?defaultRenderMoreScreenMenu:_props$renderMoreScre,_props$renderMoreScre2=props.renderMoreScreenAuthSection,renderMoreScreenAuthSection=_props$renderMoreScre2===void 0?defaultRenderMoreScreenAuthSection:_props$renderMoreScre2;

var _useTheme=useTheme(),theme=_useTheme.theme;
var styles=getStyles(theme);
var _useSafeAreaInsets=useSafeAreaInsets(),top=_useSafeAreaInsets.top;
var _useDimensions=useDimensions(),width=_useDimensions.width;
var router=useRouter();



useEffect(function(){
if(isPlatformWeb&&width>WEB_SCREEN_MAX_WIDTH.XS){
var homeLink=SOTT_DEFAULT_WEB_SCREENS.Home.getLink();
router==null?void 0:router.replace(homeLink.href,homeLink.as);
}
},[width]);

var loaderProps={
size:'large',
holderStyles:{marginTop:350}
};
return(
React.createElement(LoadingWrapper,{loaderProps:loaderProps,isLoading:isLoading},
React.createElement(ScrollView,{
style:styles.scrollViewStyle,
contentContainerStyle:[
{paddingBottom:getBottomBarHeight(),paddingTop:top},
styles.contentContainerStyle],

testID:"MoreScreen.ScrollView.1"},

React.createElement(View,{testID:"MoreScreen.View.1"},
showProfilePicker&&
renderProfilePicker({
profiles:profiles,
selectProfile:selectProfile,
isLoading:isLoading
}),
renderMoreScreenMenu({menuItems:menuItems})
),
showBottomAuthSection&&
!isLoading&&
renderMoreScreenAuthSection({
onSignInPress:onSignInPress,
onRegisterPress:onRegisterPress,
linearGradientProps:linearGradientProps
})
)
));

};

export{getMoreScreenStyles};
export default overridable(MoreScreen,'MoreScreen');